<!--<div class="d-flex justify-content-center align-items-center w-100 h-100 login-container" style="background: #f6f6f7;">-->
<div class="container">
  <div class="row" style="background: #fff;border-radius: 16px;margin-top: 43px;">
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="padding-left:0px;">
      <img src="../../../assets/img/Logo_1_1.png" alt="hq" style="width: 100%;border-radius: 16px 0 0 16px;" />
    </div>

    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
      <div class="card border-0 box-shadow rounded-0">

        <div class="card-body text-center pb-1">
          <h2 class="login_heading">Register Customer</h2>
          <a routerLink="/CustomerLogin" class="transition" style="font-family: poppins;letter-spacing: 1px;">Already have an account?<u> Sign in!</u></a>

          <form [formGroup]="form" (ngSubmit)="SignUp(form.value)" class="text-left mt-4">
            <div class="form-group">
              <input [formControl]="FirstName" class="form-control validation-field" placeholder="Name" type="text">
              <small class="text-danger" *ngIf="form.get('FirstName').touched">
                <span *ngIf="form.get('FirstName').hasError('required')">Name is required</span>
                <span *ngIf="form.get('FirstName').hasError('minlength')">Minimum of 3 characters</span>
              </small>
            </div>
            <!--<div class="form-group">
              <input [formControl]="LastName" class="form-control validation-field" placeholder="Last Name" type="text">
              <small class="text-danger" *ngIf="form.get('LastName').touched">
                <span *ngIf="form.get('LastName').hasError('required')">Last Name is required</span>
                <span *ngIf="form.get('LastName').hasError('minlength')">Minimum of 3 characters</span>
              </small>
            </div>-->
            <div class="form-group">
              <input [formControl]="MobileNo" class="form-control validation-field" placeholder="MobileNo" type="text" (blur)="CheckMobile()" maxlength="10">
              <small class="text-danger" *ngIf="form.get('MobileNo').touched">
                <span *ngIf="form.get('MobileNo').hasError('required')">Mobile is required</span>
                <span *ngIf="checkNo==10">Mobile Exist</span>
              </small>
            </div>
            <div class="form-group">
              <input [formControl]="Email" class="form-control validation-field" placeholder="Email" type="text">
              <small class="text-danger" *ngIf="form.get('Email').touched"></small>
            </div>
            <div class="form-group">
              <button [disabled]="!form.valid" class="btn btn-block" type="submit" style="background: #bb1278;color: white;" (click)="Sendsms()">Sign up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!--</div>-->

<div class="modal" id="myModal1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Otp has been send to your mobile</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">Ã—</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <input [(ngModel)]="otp" class="form-control Caps" placeholder="Enter Otp" type="text">
        </div>
      </div>
      <div class="modal-footer">

        <button type="submit" class="btn btn-primary" (click)="CheckOtp(otp)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

import { Component, OnInit, ViewChild, ElementRef, NgZone, ViewEncapsulation } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
import { MessageService } from 'primeng/api';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { DatePipe } from '@angular/common';
import { debounce } from 'rxjs/operators';
import { Validator, FormBuilder, FormGroup } from '@angular/forms';

declare var google: any
declare var $: any
//import {GoogleMap } from '@agm/core/services/google-maps-types';

@Component({
    selector: 'app-add-cart',
    templateUrl: './add-cart.component.html',
    styleUrls: ['./add-cart.component.scss'],
    providers: [MessageService]
})
export class AddCartComponent implements OnInit {
    cartItems: any;
    subTotal: number;
    Price: number;
    totalPrice: number;
    LoginDetails: any;
    addressDetails: any;
    cartIndex: number;
    addressId: any;
    BookingID: any; TicketNumber: any;
    FristName: any;
    Mobile: any;
    Area: any;
    arr: any = [];
    Address: any;
    Landmark: any;
    bookdate: any;
    title: string = 'AGM project';
    latitude: any;
    longitude: any;
    lat: any;
    long: any;
    Latitude: any;
    //Droplatitude: any;
    //Droplongitude: any;
    zoom: number;
    address: string;
    Dropaddress: string;
    //searchType: any = '';
    private geoCoder;
    map: boolean
    @ViewChild('search')
    public searchElementRef: ElementRef;
    CustomerAddress: any;

    Customerfiltr: any;
    Distance1: any;
    Distance2: any;
    tripIdForAdmin: any;
    driverDetailsForAdmin: any = [];
    driverDetails: any = [];
    DriverId: any = "0";
    //  longitude: any;
    randomnumber: any;
    todayDate: string;
    isChecked: boolean = false;
    qty = 1;
    Quantity: any = 1
    isCalendarOpen: boolean = false;
    TripID: any;
    bookingDetails: any;
    MenuId: any;
    checked: any;
    trip: any;
    patientMobile: any;
    PickupLocationAddress: any;
    date1: any;
    ScheduleDatetime: any;
    startdate: any;
    BloodGroupName: any;
    VehicleTypId: any
    Email: any;
    cols: any;
    Status: any;
    PatientId: any;
    Equipment: any;
    location: any;
    public p: any;
    type: any;
    resultset = [];
    activeState: any;
    TimeSlotList: any;
    Itemtype: any;
    date: any;
    servicedetails: any;
   // AddressId: number;
    CartArray: any;
    TimeSlotList1: any;
    dlt: string;
    msg: string;
    custmobile: any;
    admin: string;
    alertCtrl: any;
    AddtocartArray: any;
    TotalCount: number; address123: any;
    mobileLengthError: boolean = false;
    HNO: any; CartStorage1: any; CartStorage: any;
    AddressId1: any;
    Caddress: any;
    AddressId: any;
    LoginDetails1: any;
    isFormValid(): boolean {
        // Check if Name, Mobile, and Email are filled
        return this.FristName && this.Mobile && this.Email;
    }

    constructor(public toast: MessageService, public cartService: CartService, private mapsAPILoader: MapsAPILoader, public http: HttpClient, private ngZone: NgZone,
        public wishlistService: WishlistService, public generalService: GeneralService, public router: Router,
        public route: ActivatedRoute, private fb: FormBuilder) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1)
        debugger
        this.CartStorage1 = (localStorage.getItem('AddtocartArray'));
        this.CartStorage = JSON.parse(this.CartStorage1)
       // this.AddressId = this.ro.snapshot.paramMap.get("AddressId");
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
            

            // this.subTotal += (this.cartItems[0].Price * this.Quantity)
            //  this.date = new Date();
            //this.date = new Date().toISOString().slice(0, 10);
        })
        this.Address = localStorage.getItem("address");
        this.address = this.Address;
        this.latitude = localStorage.getItem("latitude");
        this.longitude = localStorage.getItem("longitude");
        this.FristName = localStorage.getItem("Name");
        this.Mobile = localStorage.getItem("MobileNo");
        this.Email = localStorage.getItem("Email");
        localStorage.removeItem("address");
        localStorage.removeItem("Name");
        localStorage.removeItem("MobileNo");
        localStorage.removeItem("Email");

        this.date = this.getToday();

    }

    ngOnInit(): void {

        this.date = new Date();
        this.GetTimeSlots();
        this.refreshfilds();
        this.GetAddress();
       // this.doSomething(this.date);
       // this.CheckArray();
    }

    CheckArray() {
        debugger
        for (var i = 0; i < this.CartStorage.length; i++) {
            this.CartArray.push({ "ServiceInfoId": this.CartStorage[i].TypeServiceDetailsId, Quantity: this.CartStorage[i].Quantity, Price: this.CartStorage[i].Price / this.CartStorage[i].Quantity, RateCardID: this.CartStorage[i].RateCardID });
        }
    }
     
    getSelectedAddress(addressId: any) {
        debugger
        console.log("Selected Address ID:", addressId);
        this.Caddress = addressId
        // You can perform further operations here with the selected address ID
    }



    gootCart() {
        this.router.navigate(['/Location']);
        //if (this.FristName && this.Mobile && this.Email) { // by giving name mobile email only open location page
        //if (this.isFormValid()) {
        //    this.router.navigate(['/Location']);
        //    localStorage.setItem("Name", this.FristName);
        //    localStorage.setItem("MobileNo", this.Mobile);
        //    localStorage.setItem("Email", this.Email);
        //}
        //else {
        //    // If any of the fields is empty, display an alert
        //    this.generalService.ShowAlert('error','Please give above details (Name, Mobile, Email) before proceeding.','error');
        //}
    }

    refreshfilds() {
        this.type = 'list'
    }

    private setCurrentLocation() {
        debugger
        this.trip = 'newTrip'
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                // this.Droplatitude = position.coords.latitude;
                // this.Droplongitude = position.coords.longitude;
                this.lat = position.coords.latitude;
                this.long = position.coords.longitude;
                this.getAddress(position.coords.latitude, position.coords.longitude);
            });
        }
    }

    markerDragEnd($event: MouseEvent) {
        this.lat = $event.coords.lat;
        this.long = $event.coords.lng;
        //if (this.searchType == 'p') {
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        //} else {
        //  this.Droplatitude = $event.coords.lat;
        //  this.Droplongitude = $event.coords.lng;
        //}
        this.getAddress($event.coords.lat, $event.coords.lng);
    }

    getAddress(latitude, longitude) {
        debugger
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    console.log(results)
                    this.zoom = 12;
                    //if (this.searchType == 'p') {
                    this.address = results[0].formatted_address;
                    this.Address = results[0].formatted_address;
                    this.latitude = latitude;
                    this.longitude = longitude;

                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    changeQuantity() {
        debugger

        this.subTotal += (this.cartItems[0].Price * this.Quantity)

    }

    GetTimeSlots() {
        var url = "/api/Values/getTimeSlots";
        var date = new Date();
        this.generalService.GetData(url).then((data: any) => {
            this.TimeSlotList = data;
        }, err => {
            //this.generalService.presentToast('Something went wrong. Please try again later.')
        });
    }

    ItemType(value) {
        this.activeState = value;
        this.Itemtype = value
    }

    doSomething(date: Date) {
        debugger
        let d1 = new Date();
        let d2 = new Date(date);
        let same = d1.getDate() === d2.getDate();
        if (same) {

            var url = "/api/Values/getTimeSlotsToday";
            this.generalService.GetData(url).then((data: any) => {
                this.TimeSlotList = data;
                if (!this.TimeSlotList) {
                    this.generalService.ShowAlert('error.', 'No Time Slots are Avalible', 'error');
                }

            }, err => {
                this.generalService.ShowAlert('error.', 'No Time Slots are Avalible', 'error');
            });

        }
        else {
            this.activeState = null; // Initially clear the selected time slot

        }
    }

    getToday(): string {

        let today = new Date();
        let invalidDate = new Date();
        return new Date(invalidDate.setDate(today.getDate())).toISOString().split('T')[0]

    }
    GetAddress() {
        debugger
        let formData: FormData = new FormData();
        formData.append('CustomerId', this.LoginDetails[0].CustomerId);
        var url = "/api/Values/getCustomerAddresses";
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.CustomerAddress = data;
            this.Customerfiltr = this.CustomerAddress.filter((id: any) => id.Area != undefined || id.CityName != undefined || id.CountryName != undefined)
            if (data.length > 0) {
                this.activeState = this.CustomerAddress[0].AddressId;
            }

        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }

    AddnewAddress(HNO: any, Landmark: any, Area: any, flag: any) {
        if (this.HNO != undefined || this.Landmark != undefined || this.Area != undefined) {
            let array = [{
                CustomerId: this.LoginDetails[0].CustomerId,
                Area: HNO + ',' + Landmark + '.' + Area,
                Latitude: this.latitude,
                Longitude: this.longitude,
                /*Address :this.Address*/
                CountryId: 1,
                StateId: 1,
                City: 1
            }];
            this.router.navigate(['/location', { details: JSON.stringify(array) }]);

        }
        else {
            this.generalService.ShowAlert('ERROR', "please enter all fields.", 'error')
        }
    }
    //bookproduct() {
    //  debugger
    //    if (!this.cartItems || this.cartItems.length === 0) {
    //        this.generalService.ShowAlert('ERROR', 'Please add items to the cart before booking.', 'error');
    //        return; // Stop further processing
    //    }
    //    if (!this.date || this.date === null) {
    //        this.generalService.ShowAlert('ERROR', 'Please select a date', 'error');
    //        return;
    //    }
    //    if (this.FristName == '' || this.FristName == undefined || this.FristName == null || this.FristName.trim() === '') {
    //        this.generalService.ShowAlert('ERROR', 'Please enter the Name.', 'error')

    //    } else if (this.Mobile == '' || this.Mobile == undefined || this.Mobile == null || this.Mobile.toString().length !== 10) {
    //        this.generalService.ShowAlert('ERROR', 'Please enter the mobile.', 'error')

    //    }
    //    else if (this.Email == '' || this.Email == undefined || this.Email == null) {
    //        this.generalService.ShowAlert('ERROR', 'Please enter the Email.', 'error')

    //    }
    //    else if (this.activeState == '' || this.activeState == undefined || this.activeState == null) {
    //        this.generalService.ShowAlert('ERROR', 'Please select the slot.', 'error')
    //    }
    //    else if (this.latitude == '' || this.latitude == undefined || this.latitude == null) {
    //        this.generalService.ShowAlert('ERROR', 'Please enter location.', 'error')
    //    }
    //    else if (this.date == undefined || this.date == null) {
    //        this.generalService.ShowAlert('ERROR', 'Please select Date', 'error')
    //    }

    //    else if (this.FristName != '' || this.FristName != undefined ||
    //        this.FristName != null && this.Mobile != '' || this.Mobile != undefined ||
    //        this.Mobile != null && this.Email != '' || this.Email != undefined ||
    //        this.Email != null && this.activeState != '' || this.activeState != undefined ||
    //        this.activeState != null && this.latitude != '' || this.latitude != undefined ||
    //        this.latitude != null && this.date != undefined || this.date != null) {
    //  debugger
    //  this.arr = [];
    //  this.AddressId = 1
    //   for (var i = 0; i < this.cartItems.length; i++) {


    //     this.arr.push({
    //         FirstName: this.FristName,
    //         Mobile: this.Mobile,
    //         Email: this.Email,
    //         Address: this.Address,
    //         RequestedDate: this.date,
    //         TimeSlot: this.activeState,
    //         TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
    //         ServiceInfoId: this.cartItems[i].TypeServiceDetailsId,
    //         ServiceInfoName: this.cartItems[i].TypeServiceDetail,
    //         Quantity: this.cartItems[i].Quantity
    //       , Price: this.cartItems[i].Price * this.cartItems[i].Quantity,
    //       latitude: this.latitude,
    //       longitude: this.longitude,
    //     });


    //  }
    //  let formData: FormData = new FormData();
    //  formData.append('AllSVdetils', JSON.stringify(this.arr));

    //  var url = "api/Values/Insert_web_bookings";

    //  this.generalService.PostData(url, formData).then((data: any) => {
    //    debugger
    //      this.BookingID = data.ServiceTicketNumber;

    //    this.sendsms2();

    //    if (data == "SUCCESS") {
    //      this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your slot successfully booked, one of our service engineer will contact you soon!' });
    //    }
    //    else {
    //      this.toast.add({ severity: 'warning', summary: 'Info', life: 1000, detail: 'Somting went wrong!' });
    //    }
    //    let formData1: FormData = new FormData();
    //    formData1.append('Details', JSON.stringify(this.arr));
    //    var url = "api/Values/mailforcustomerWebBooking";
    //    this.generalService.PostData(url, formData1).then((data: any) => {
    //    }, err => {

    //      // this.generalService.toast.add('Something went wrong. Please try again later.')
    //    });

    //      let formData2: FormData = new FormData();
    //    formData2.append('Details', JSON.stringify(this.arr));
    //    var url = "api/Values/mailforcustomerWebBooking1";
    //    this.generalService.PostData(url, formData2).then((data: any) => {
    //    }, err => {

    //      // this.generalService.toast.add('Something went wrong. Please try again later.')
    //    });

    //    let formData: FormData = new FormData();
    //    formData.append('serviceTicketId', data);
    //    var url = "api/Values/sendServiceticketNotification";
    //    this.generalService.PostData(url, formData).then((data: any) => {
    //    }, err => {

    //     // this.generalService.toast.add('Something went wrong. Please try again later.')
    //    });

    //    this.router.navigate(['SuccessPage'])
    //      .then(() => {
    //        window.location.reload();
    //      });
    //    //this.router.navigate(['/SuccessPage']);
    //  });

    //}
    //  }
    bookproduct1() {
        debugger;
        if (!this.cartItems || this.cartItems.length === 0) {
            this.generalService.ShowAlert('ERROR', 'Please add items to the cart before booking.', 'error');
            return; // Stop further processing
        }
        if (!this.date || this.date === null) {
            this.generalService.ShowAlert('ERROR', 'Please select a date', 'error');
            return;
        }
        //if (this.FristName == '' || this.FristName == undefined || this.FristName == null || this.FristName.trim() === '') {
        //    this.generalService.ShowAlert('ERROR', 'Please enter the Name.', 'error');
        //    return;
        //} else if (this.Mobile == '' || this.Mobile == undefined || this.Mobile == null || this.Mobile.toString().length !== 10) {
        //    this.generalService.ShowAlert('ERROR', 'Please enter the mobile.', 'error');
        //    return;
        //} else if (this.Email == '' || this.Email == undefined || this.Email == null) {
        //    this.generalService.ShowAlert('ERROR', 'Please enter the Email.', 'error');
        //    return;
        //}
        if (this.activeState == '' || this.activeState == undefined || this.activeState == null) {
            this.generalService.ShowAlert('ERROR', 'Please select the slot.', 'error');
            return;
        //} else if (this.latitude == '' || this.latitude == undefined || this.latitude == null) {
        //    this.generalService.ShowAlert('ERROR', 'Please enter location.', 'error');
        //    return;
        }

        // Calculate total price
        let totalPrice = 0;
        for (let i = 0; i < this.cartItems.length; i++) {
            totalPrice += this.cartItems[i].Price * this.cartItems[i].Quantity;
        }

        // Continue with booking if all validations pass
        // Modified condition to check all fields before booking
        if (this.FristName != '' && this.Mobile != '' && this.Email != '' && this.activeState != '' && this.latitude != '' && this.date != undefined && this.date != null) {
            this.arr = [];
           // this.AddressId = 1;
            for (let i = 0; i < this.cartItems.length; i++) {
                this.arr.push({
                    CustomerId: this.LoginDetails[0].CustomerId,
                    FirstName: this.LoginDetails[0].FirstName,
                    Mobile: this.LoginDetails[0].Mobile,
                    Email: this.LoginDetails[0].Email,
                    Address: this.Caddress.Address,
                    RequestedDate: this.date,
                    TimeSlot: this.activeState,
                    TotalPrice: totalPrice, // Assign total price here
                    ServiceInfoId: this.cartItems[i].TypeServiceDetailsId,
                    ServiceInfoName: this.cartItems[i].TypeServiceDetail,
                    Quantity: this.cartItems[i].Quantity,
                    Price: this.cartItems[i].Price * this.cartItems[i].Quantity,
                    AddressId: this.Caddress.AddressId,
                    //Latitude: this.latitude,
                    //Longitude: this.longitude,
                });
            }
            let formData: FormData = new FormData();
            formData.append('AllSVdetils', JSON.stringify(this.arr));

            var url = "api/Values/Insert_web_bookings";

            this.generalService.PostData(url, formData).then((data: any) => {
                debugger;
                this.TicketNumber = data.MSG;
                this.BookingID = data.ServiceTicketNumber;
                this.sendsms1();
               // this.sendsms2();

                if (data == "SUCCESS") {
                    this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your slot successfully booked, one of our service engineer will contact you soon!' });
                } else {
                    this.toast.add({ severity: 'warning', summary: 'Info', life: 1000, detail: 'Something went wrong!' });
                }

                let formData3: FormData = new FormData();
                formData3.append('serviceTicketId', this.TicketNumber);
                formData3.append('path', '');
                formData3.append('Img', '');
                var url3 = "api/Values/sendNotification2";
                this.generalService.PostData(url3, formData3).then((data: any) => {
                }, err => {
                    // this.generalService.toast.add('Something went wrong. Please try again later.')
                });

                let formData1: FormData = new FormData();
                formData1.append('Details', JSON.stringify(this.arr));
                var url1 = "api/Values/mailforcustomerWebBooking";
                this.generalService.PostData(url1, formData1).then((data: any) => {
                }, err => {
                    // this.generalService.toast.add('Something went wrong. Please try again later.')
                });

                let formData2: FormData = new FormData();
                formData2.append('Details', JSON.stringify(this.arr));
                var url2 = "api/Values/mailforcustomerWebBooking1";
                this.generalService.PostData(url2, formData2).then((data: any) => {
                }, err => {
                    // this.generalService.toast.add('Something went wrong. Please try again later.')
                });

               

                this.router.navigate(['SuccessPage'])
                    .then(() => {
                        window.location.reload();
                    });
                //this.router.navigate(['/SuccessPage']);
            });
        } else {
            this.generalService.ShowAlert('ERROR', 'Please fill in all the required fields.', 'error');
        }
    }
    bookproduct() {
        debugger;
        if (!this.cartItems || this.cartItems.length === 0) {
            this.generalService.ShowAlert('ERROR', 'Please add items to the cart before booking.', 'error');
            return; // Stop further processing
        }
        if (!this.date || this.date === null) {
            this.generalService.ShowAlert('ERROR', 'Please select a date', 'error');
            return;
        }
        if (this.activeState == '' || this.activeState == undefined || this.activeState == null) {
            this.generalService.ShowAlert('ERROR', 'Please select the slot.', 'error');
            return;
        }
        if (this.FristName != '' && this.Mobile != '' && this.Email != '' && this.activeState != '' && this.latitude != '' && this.date != undefined && this.date != null) {
            let totalPrice = 0;
            for (let i = 0; i < this.cartItems.length; i++) {
                totalPrice += this.cartItems[i].Price * this.cartItems[i].Quantity;
            }
            var obj = [{
                CustomerId: this.LoginDetails[0].CustomerId,
                FirstName: this.LoginDetails[0].FirstName,
                Mobile: this.LoginDetails[0].Mobile,
                Email: this.LoginDetails[0].Email,
                Address: this.Caddress.Address,
                RequestedDate: this.date,
                TimeSlot: this.activeState,
                TotalPrice: totalPrice, // Assign total price here
                AddressId: this.Caddress.AddressId,
            }]
            this.arr = [];
            for (let i = 0; i < this.cartItems.length; i++) {
                this.arr.push({                   
                    ServiceInfoId: this.cartItems[i].TypeServiceDetailsId,
                    ServiceInfoName: this.cartItems[i].TypeServiceDetail,
                    Quantity: this.cartItems[i].Quantity,
                    Price: this.cartItems[i].Price * this.cartItems[i].Quantity                   
                });
            }
            let formData: FormData = new FormData();
            formData.append('serviceTicketDetails', JSON.stringify(obj));
            formData.append('services', JSON.stringify(this.arr));
            var url = "api/Values/InsertServiceTicket";
            //formData.append('AllSVdetils', JSON.stringify(this.arr));
            //var url = "api/Values/Insert_web_bookings";

            this.generalService.PostData(url, formData).then((data: any) => {
                debugger;
                if (data && data.ServiceTicketNumber && data.ServiceTicketNumber !== "0") {
                    this.BookingID = data.ServiceTicketNumber;
                    this.TicketNumber = data.MSG;
                    let formData3: FormData = new FormData();
                    formData3.append('serviceTicketId', this.TicketNumber);
                    formData3.append('path', '');
                    formData3.append('Img', '');
                    var url3 = "api/Values/sendNotification2";
                    this.generalService.PostData(url3, formData3).then((data: any) => {
                    }, err => {
                        // this.generalService.toast.add('Something went wrong. Please try again later.')
                    });
                    this.sendsms1();
                    if (data == "SUCCESS") {
                        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your slot successfully booked, one of our service engineers will contact you soon!' });
                    } else {
                        this.toast.add({ severity: 'warning', summary: 'Info', life: 1000, detail: 'Something went wrong!' });
                    }
                    debugger
                    this.cartService.removeAllcart();                    
                    this.cartItems = {};
                    localStorage.removeItem("Cartcountitems");
                    localStorage.removeItem("AddtocartArray");
                    localStorage.removeItem("cartCount");
                    //let formData3: FormData = new FormData();
                    //formData3.append('serviceTicketId', this.TicketNumber);
                    //formData3.append('path', '');
                    //formData3.append('Img', '');
                    //var url3 = "api/Values/sendNotification2";
                    //this.generalService.PostData(url3, formData3).then((data: any) => {
                    //}, err => {
                    //    // this.generalService.toast.add('Something went wrong. Please try again later.')
                    //});
                   
                    //let formData1: FormData = new FormData();
                    //formData1.append('Details', JSON.stringify(this.arr));
                    //var url1 = "api/Values/mailforcustomerWebBooking";
                    //this.generalService.PostData(url1, formData1).then((data: any) => {
                    //}, err => {
                    //    // this.generalService.toast.add('Something went wrong. Please try again later.')
                    //});

                    //let formData2: FormData = new FormData();
                    //formData2.append('Details', JSON.stringify(this.arr));
                    //var url2 = "api/Values/mailforcustomerWebBooking1";
                    //this.generalService.PostData(url2, formData2).then((data: any) => {
                    //}, err => {
                    //    // this.generalService.toast.add('Something went wrong. Please try again later.')
                    //});
                   
                    this.router.navigate(['SuccessPage']).then(() => {
                     //   window.location.reload();
                    });
                } else {
                    this.generalService.ShowAlert('ERROR', 'Failed to get a valid ticket number.', 'error');
                }
            }).catch(err => {
                console.error('Error in booking:', err);
                this.generalService.ShowAlert('ERROR', 'Failed to book the slot. Please try again later.', 'error');
            });
        } else {
            this.generalService.ShowAlert('ERROR', 'Please fill in all the required fields.', 'error');
        }
    }



    sendsms1() {
        debugger

        this.msg = 'Thank you for booking with us. Your booking ID is ' + this.BookingID + '. To check the current status of your booking, you can also login to our mobile app https://bit.ly/41iTwj3. If you need any further support, please contact us at 8074507671. HQSRVZ';
        this.dlt = '1207168070102193857';
        this.admin = '9866079507';       
        let formdata = new FormData();
        formdata.append("mobile", this.admin);
        formdata.append("message", this.msg);
        formdata.append("DLTTEID", this.dlt);
        var url = 'api/Values/sendSMS';
        this.generalService.PostData(url, formdata).then((data: any) => {
            
        });
        this.sendsms2();

    }

    sendsms2() {
        debugger

        this.msg = 'Thank you for booking with us. Your booking ID is ' + this.BookingID + '. To check the current status of your booking, you can also login to our mobile app https://bit.ly/41iTwj3. If you need any further support, please contact us at 8074507671. HQSRVZ';
        this.dlt = '1207168070102193857'    

        let formdata = new FormData();
        formdata.append("mobile", this.LoginDetails[0].Mobile)
        formdata.append("message", this.msg)
        formdata.append("DLTTEID", this.dlt)
        var url = 'api/Values/sendSMS';
        this.generalService.PostData(url, formdata).then((data: any) => {
        })
    }

    viewDetails() {
        debugger

        $('#myModal').modal('show');

    }

    close() {
        $('#myModal').modal('hide');

    }

    onCheckboxChange() {
        this.isChecked = !this.isChecked;
    }


    RemoveItems(i: any, value: any) {
        debugger
        this.cartItems.splice(i, 1);
        this.cartItems.splice(i, 1);
        //this.addtocartlist.splice(i, 1);
        localStorage.setItem("Productdetails", JSON.stringify(this.cartItems));
        //  this.totalcartitems = localStorage.setItem("totalcartitems", JSON.stringify(this.cartItems.length));
        //   this.header.getcartitems(this.cartItems.length);
        //this.userservice.edituser(this.cartItems.length);
        this.generalService.ShowAlert('SUCCESS', "Item removed from Cart Successfully", 'success');
        if (this.cartItems.length != 0) {
            this.refreshfilds();
        }
        //else {
        //  this.totalPrice1 = 0;
        //  this.GstPercentage = 0;
        //  this.totalPrice = 0;
        //  this.GstPrice = 0;
        //}
    }


    async BookSlot(date) {
        debugger

        if (date == '' || date == undefined || date == null) {
            this.toast.add({ severity: 'Info', summary: 'warning', life: 1000, detail: 'Please select slot booking date"' });

        }
        else if (this.activeState == '' || this.activeState == undefined || this.activeState == null) {
            this.toast.add({ severity: 'Info', summary: 'warning', life: 1000, detail: 'Please select slot booking date"' });
        }

    }

    /*Below deleteFromCart() method will fire when user delete the product from the cart*/
    deleteFromCart(index) {
        this.cartService.removeCartItems(index)
        this.changeQuantity()
    }


}

<div class="container" style="font-family:poppins;">
  
    <nav aria-label="breadcrumb" style="margin-top:20px">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b>Home</b></a></li>
        <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy">Cart Items </b></li>
      </ol>
    </nav>


    <!--<b class="air">Cart Items</b>-->
 

  <div class="col-md-12">
    <div *ngFor="let item of cartItems">
      <i class="fa fa-minus-square-o fa-lg" (click)="remove(item)"></i> <b style="font-size:18px;padding: 5px 5px;">{{item.TypeServiceDetail}}</b>
    </div>
    <button *ngIf="!cartItems.length" class="btn-primary" routerLink="/Home">Go Back to Service</button>
    <button *ngIf="cartItems.length" class="btn-primary" routerLink="/Login">Proceed to Buy</button>
  </div>

</div>

import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { GeneralService } from '../../service/general.service';

declare var $: any;

@Component({
    selector: 'app-customer-login',
    templateUrl: './customer-login.component.html',
    styleUrls: ['./customer-login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomerLoginComponent {
    public form: FormGroup;
    public Mobile: AbstractControl;
    randomnumber: any;
    otp: any;
    PhoneNo: any;
    LoginDetails: any;
    TemplateId: string;

    constructor(public router: Router, fb: FormBuilder, public generalService: GeneralService) {
        this.form = fb.group({
            'Mobile': ['', Validators.compose([Validators.required, Validators.pattern(/^[6-9]\d{9}$/)])]
        });
    }

    onSubmit(val) {
        debugger
        this.PhoneNo = val.Mobile;
        if (this.form.valid) {
            var UploadFile = new FormData();
            UploadFile.append("flag", "1");
            UploadFile.append("mobile", this.PhoneNo);
            var url = 'api/Values/verifyMobileorEmail';
            this.generalService.PostData(url, UploadFile).then(data => {
                if (data == 'NOT EXISTS') {
                    this.generalService.ShowAlert('ERROR', 'You dont have an account,please Sign up', 'error');
                    this.router.navigate(['/CustomerRegistration']);
                  
                } else {
                       this.otp = "";
                    this.SendOtp();
                    $('#myModal3').modal('show');
                }
            }, err => { 
                // Handle error
            });
        }
    }

    SendOtp() {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "HQSRVZ Login OTP " + this.randomnumber + " HQSRVZ";
        var UploadFile = new FormData();
        UploadFile.append("mobile", this.PhoneNo);
        UploadFile.append("message", msg);
        UploadFile.append("DLTTEID", "1207161701312347505");
        var url = '/api/Values/sendSMS'
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {
                // Handle success
            } else {
                // Handle error
            }
        });
    }

    VerifyOtp(otp) {
        if (this.randomnumber == otp) {
            $('#myModal3').modal('hide');
            let formData: FormData = new FormData();
            formData.append('username', this.PhoneNo);
            var url = "api/Values/customerLogin";
            this.generalService.PostData(url, formData).then((data: any) => {
                debugger
                this.LoginDetails = localStorage.setItem("LoginDetails", JSON.stringify(data));
            })
            this.router.navigate(['/Home']);
        } else {
            alert("Invalid OTP");
        }
    }

    keyPress(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
}

import { GeneralService } from '../../service/general.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { window } from 'rxjs/operators';
import { EmailValidators } from 'ngx-validators';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {
  HomeUrl: any;
  add: boolean = true;
  dataResult: any = [];
  arr: any[];
  dataResult1: any = [];
  Products: any = [];
  ProductsList: any = [];
  TypeServicesCategoryID: any;
  CatID: 3;
  dataResult11: any;
  TypeServicesCategoryId: number;
  allview: boolean;
  searchcompany: any;
  searchlist: any;
  selectedproducts: any;
  searchcomp: any;
  ProductName: any;
  gridview: boolean = true;

  constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {
    debugger
    /*this.ProductName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();*/
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {
    //this.Getcategories();
     this.getCat();
  }




  Getcategories() {
    debugger
    var url = "api/Nutrigene/Get_subCategories_basedonCatid3";
    this.generalService.GetData(url).then(data => {
      this.dataResult = data;
      this.gridview =false
      //console.log(data);
    }, err => {
      // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }

  getCat1() {
    debugger
    this.getCat();
    this.allview = true;
  }
  getCat() {

    debugger
    var url = "api/Values/getServiceCategories";
    this.generalService.GetData(url).then(data => {
      debugger
      this.dataResult = data;
     
 
      //console.log(data);
    }, err => {
      // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }


  getSubCat(ServiceCategoryId) {
    debugger
   
    this.router.navigate(['/Category/' + ServiceCategoryId]);
            
      
   
  }


  navigatingToSelectedProduct(data) {
    debugger
    //var prodName = ""; var prodarr = []
    //prodarr.push(data)
    //var str = data.TypeServiceCategory.split(" ")//spliting the category name into string array where space occured
    //for (let i = 0; i < str.length; i++) {
    //  prodName += "_" + str[i];
    //}
    //this.generalService.setProducts(prodarr)
    this.router.navigate(['/Typeofservices/' + data]);
  }




  rating1(el: HTMLElement) {

    el.scrollIntoView();
  }
  rating(el: HTMLElement) {

    el.scrollIntoView();
  }
}




<br />
<!--<footer>
    <div class="container-fluid" style="background-image: linear-gradient(to right, #487ad7, #60d960);">
        <div class="container">
            <div class="row ">
                <div class="col-lg-4 col-md-3">
                    <div class="copyright">
                        &copy; Copyright <strong><span>HQ AC</span></strong>. All Rights Reserved
                    </div>
                </div>
                <div class="col-lg-2">
                    <p class="Privacy" > <a href="https://hqserviz.com/Privacy_Policy.html" target="_blank" style="color:white;"> Privacy Policy  </a> </p>
                </div>
                <div class="col-lg-2">
                    <p class="Terms" style="color:white;"> <a href="https://hqserviz.com/TermsandConditions.html" target="_blank" style="color:white;">Terms And Conditions </a></p>
                </div>
                <div class="col-lg-4">
                    <div class="credits">
                        Powered by : <a href="http://gagri.net/" target="_blank" style="color:white;">  Gagri Global IT Services Pvt. Ltd.</a>
                        3rd Floor Sai Arcade, #2-17 & 2-18,
                        Vani Nagar Bus Stop, Opposite Canara Bank,
                        Malkajgiri, Secunderabad, Hyderabad, India- 500047
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>-->
<br />
<div class="pg-footer">
    <footer class="footer" style="background-image: linear-gradient(135deg, #005bea, #00c6fb);">
        <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
            <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
        </svg>
        <div class="footer-content">
            <!--<div class="footer-content-column"><br />
        <h2 class="footer-menu-name"> About HQ</h2>
        <div class="footer-logo">
            <div>
                HQ AC Engineers is your trusted partner for all your cooling needs in Hyderabad. With a commitment
                to excellence, we offer a comprehensive range of AC services tailored to meet the unique requirements of
                our customers. Whether you need AC repair, servicing, maintenance, or installation, our team of skilled
                technicians is equipped to handle it all.
            </div>
            <a class="footer-logo-link" href="#">
                <p> </p>
            </a>
        </div>

    </div>-->
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name">About HQ</h2><br />
                    <P>
                        HQ AC Engineers is your trusted partner for all your cooling needs in Hyderabad. With a commitment
                        to excellence, we offer a comprehensive range of AC services tailored to meet the unique requirements of
                        our customers.
                    </P>
                </div>
                
            </div>
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name"> Quick Links</h2>
                    <ul id="menu-quick-links" class="footer-menu-list">
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a rel="noopener noreferrer" routerLink="Home">Home</a>
                        </li>
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a rel="noopener noreferrer" routerLink="/Aboutus">About HQ</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="BookNow">Categories</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="faq">FAQ's</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                            <a routerLink="contactus">Contact Us</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="BookNow">Book Now</a>
                        </li>
                    </ul>
                </div>
                <div class="footer-menu">
                    <!-- <h2 class="footer-menu-name"> Legal</h2>-->
                    <ul id="menu-legal" class="footer-menu-list">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                            <a href="https://hqserviz.com/Privacy_Policy.html">Privacy Policy</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a href="https://hqserviz.com/TermsandConditions.html">Terms And Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name">Services</h2>
                    <ul id="menu-quick-links" class="footer-menu-list">
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a rel="noopener noreferrer" routerLink="BookNow">Air Conditioner</a>
                        </li>
                        <li class="menu-item menu-item-type-custom menu-item-object-custom">
                            <a rel="noopener noreferrer" routerLink="BookNow">Chimney</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a rel="noopener noreferrer" routerLink="BookNow">Constructions</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="BookNow">Electricians</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                            <a routerLink="BookNow">Geyser</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="BookNow">Microwave</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                            <a routerLink="BookNow">Refrigerator</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="BookNow">Telivision</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                            <a routerLink="BookNow">Washing Mechine</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page">
                            <a routerLink="BookNow">Water purifier</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-content-column">


            </div>
            <div class="footer-content-column">
                <div class="footer-menu">
                    <h2 class="footer-menu-name"> Address</h2><br />
                    <address>
                        3rd Floor Sai Arcade, #2-17 & 2-18,
                        Vani Nagar Bus Stop, Opposite Canara Bank,
                        Malkajgiri, Secunderabad, Hyderabad, India- 500047
                    </address>
                </div>

                <div class="footer-menu">
               
                    <a class="btn-get-started scrollto" href="https://play.google.com/store/apps/details?id=com.gg.hqac " target="_blank">
                       <i class="fa-brands fa-google-play"></i>Customer App
                    </a>
                    <br />
                    <a class="btn-get-started scrollto" href="https://play.google.com/store/apps/details?id=com.gg.hqacEng"target="_blank">
                        <i class="fa-brands fa-google-play"></i>Technician App

                    </a>
                </div>

            </div>
            <!--<div class="footer-content-column">
        <div class="footer-call-to-action">
            <h2 class="footer-call-to-action-title"> Let's Chat</h2>
            <p class="footer-call-to-action-description"> Have a support question?</p>
            <a class="footer-call-to-action-button button" href="#" target="_self"> Get in Touch </a>
        </div>
        <div class="footer-call-to-action">
            <h2 class="footer-call-to-action-title"> You Call Us</h2>
            <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:8074507671" target="_self"> 8074507671 </a></p>
        </div>
    </div>-->

        </div>
        <!--<div class="footer-copyright">
            <div class="footer-copyright-wrapper">
                <p class="footer-copyright-text">
                    <a class="footer-copyright-link" href="#" target="_self">  &copy; Copyright <strong><span>HQ AC</span></strong>. All Rights Reserved </a>
                </p>
                <p class="footer-copyright-text">
                    Powered by : <a href="http://gagri.net/" target="_blank" style="color:white;">  Gagri Global IT Services Pvt. Ltd.</a>
                </p>
            </div>
        </div>-->
        <div class="container">
           <!-- <div class="col-lg-1"></div>-->
            <div class="col-lg-6">
                <p class="footer-copyright-text">
                    <a class="footer-copyright-link" href="#" target="_self">  &copy; Copyright <strong><span>HQ AC</span></strong>. All Rights Reserved </a>
                </p>
            </div>
            <div class="col-lg-5" style="text-align:right;">
                <p class="footer-copyright-text">
                    Powered by : <a href="http://gagri.net/" target="_blank" style="color:white;">  Gagri Global IT Services Pvt. Ltd.</a>
                </p>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </footer>
</div>

import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    cartItems: any = [];
    cartItems1: any;
  constructor(public generalService: GeneralService) { }

  ngOnInit(): void {
      this.cartItems1 = localStorage.getItem('cart')
      this.cartItems = JSON.parse(this.cartItems1)
    console.log(this.cartItems);
  }
  remove(obj: any) {
    var index = this.cartItems.findIndex(re => re.TypeServiceDetailsId == obj.TypeServiceDetailsId);
    this.cartItems.splice(index, 1);
    this.generalService.ShowAlert('SUCCESS', 'successfully removed', 'success');
    localStorage.setItem('cart', JSON.stringify(this.cartItems))
    console.log(this.cartItems);
  }

}

import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-spinneroverlay',
  templateUrl: './spinneroverlay.component.html',
  styleUrls: ['./spinneroverlay.component.scss']
})
export class SpinneroverlayComponent implements OnInit {
  @Input() public message: string;
  constructor() { }

  ngOnInit(): void {
  }

}

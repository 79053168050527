


<div class="container">
  <nav aria-label="breadcrumb" style="margin-top:20px">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b>Home</b></a></li>
      <li class="breadcrumb-item" style="cursor:pointer;"><b>Login</b></li>
    </ol>
  </nav>
  <br /><br />
  <div class="col-md-6 ">
    <p>
      HQ AC Engineers has been providing expert cooling solutions in Hyderabad. We provide a comprehensive range of AC
      services to the customers. We work with AC Repair, Services, Maintenance and Installation in Hyderabad like, Samsung,
      LG, Hitachi, Voltas Blue Star and etc.
    </p>
    <p>Get your window or split AC service done by HQ trained technicians, with our AC service in Hyderabad within couple of hrs. Get 100 day repair warranty. We have trained technicians whos background is verified and who are well equipped for Power Jet AC service, for deeper cleaning and better cooling. Our engineers will use genuine spare parts for ac repair. HQ will help you connect with the best AC services to support your needs at your doorstep.</p>
  </div>
  <div class="col-md-6 bg-white mt-10 p-5">

    <h3 class="pb-3" style="text-align:center;color:#09cad3;">Login Form</h3>
    <div class="form-style">
      <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
        <div class="form-group pb-3">

          <input formControlName="Mobile" class="form-control validation-field" placeholder=" Enter Mobile" type="text" (keypress)=keyPress($event) maxlength="10">
          <small class="text-danger" *ngIf="form.get('Mobile').touched && form.get('Mobile').hasError('required')">Mobile is required</small>
        </div>
        <div class="pb-2">
          <button type="submit" [disabled]="!form.valid" class="btn btn-info w-100 font-weight-bold mt-2">Submit</button>
        </div>
      </form>

      <div class="pt-4 text-center" routerLink="/CustomerRegistration">
        Get Members Benefit. <a style="color:blue;cursor:pointer;font-size:16px;">Sign Up</a>
      </div>
    </div>

  </div>

</div>


<!--This is for the otp number to be displayed in the popup model.-->
<div class="modal" id="myModal3">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title">Enter OTP received on {{PhoneNo}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:black;">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Enter OTP<span style="color:red">*</span></label>
          <input [(ngModel)]="otp" class="form-control Caps" placeholder="Enter valid OTP" type="text">
        </div>
        <div class="row" style="float:right;cursor:pointer;margin-right:2px;">
          <a class="resend" (click)="SendOtp()">Resend OTP</a>
          <!-- <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>-->
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>




<nav aria-label="breadcrumb" class="breadcrumb1">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/">FAQ's</a> </li>
            <div>Frequently Asked Questions</div>

        </ul>
    </div>
</nav>
<section class="faq section-bg" id="faq"style="background-color:whitesmoke">
    <div class="container">
        <div class="section-title" data-aos="fade-up">
            <br />
            <h2>Frequently Asked Questions</h2>
        </div>
        <ul class="faq-list">
            <li data-aos="fade-up" data-aos-delay="100">
                <a class="" data-toggle="collapse" href="#faq1">
                    Do I need to procure any parts needed or will the professional get them?
                    <i class="icofont-simple-up"></i>
                </a>
                <div class="collapse show" data-parent=".faq-list" id="faq1">
                    <p> This is totally your discretion. If you want, you can get the parts yourself or the professional can get it for you. </p>
                </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
                <a class="collapsed" data-toggle="collapse" href="#faq2">
                    What if there is no service availed? How much would I have to pay?
                    <i class="icofont-simple-up"></i>
                </a>
                <div class="collapse" data-parent=".faq-list" id="faq2">
                    <p>In a scenario where the professional has checked the AC and you don’t want to go ahead with the service, you will be required to pay the visitation charge of Rs. 249.</p>
                </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
                <a class="collapsed" data-toggle="collapse" href="#faq3">
                    What is the quality of the parts procured by the professional?
                    <i class="icofont-simple-up"></i>
                </a>
                <div class="collapse" data-parent=".faq-list" id="faq3">
                    <p>The spare parts, if needed, during the repair of your AC are 100% original from companies like Hitachi, Samsung, Daikin, Lloyd, Bluestar, etc. We even provide a service guarantee for 30 days.</p>
                </div>
            </li>
        </ul>
    </div>
</section>
<style>
    @media only screen and (max-width:600px) {
        .breadcrumb1 {
            position: relative;
            /*background: url('assets/img/bg.jpg') center center/cover;*/
            padding: 6px;
            margin-bottom: 5%;
            margin-top: -13%;
        }
            .breadcrumb1 li {
                display: inline-block;
                color: white;
                cursor: pointer;
                font-size: 13px;
            }
    }
</style>

import { Component } from '@angular/core';

@Component({
  selector: 'app-faquestion',
  templateUrl: './faquestion.component.html',
  styleUrl: './faquestion.component.scss'
})
export class FaquestionComponent {

}

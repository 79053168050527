import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { PageLayoutComponent } from './page-layout/page-layout.component'
import { HomeComponent } from './page-layout/home/home.component'
import { PageHeaderComponent } from './page-layout/page-header/page-header.component'
import { PageFooterComponent } from './page-layout/page-footer/page-footer.component'
import { CustomerLoginComponent } from './page-layout/customer-login/customer-login.component'
import { CustomerRegistrationComponent } from './page-layout/customer-registration/customer-registration.component';
import { AboutUsComponent } from './page-layout/about-us/about-us.component';
import { BookingComponent } from './page-layout/booking/booking.component';
import { CartComponent } from './page-layout/cart/cart.component';
import { CategoriesComponent } from './page-layout/categories/categories.component';
import { SubcategoriesComponent } from './page-layout/subcategories/subcategories.component';
import { AddCartComponent } from './page-layout/add-cart/add-cart.component';
import { ServicecatsComponent } from './page-layout/servicecats/servicecats.component';
import { SuccesspageComponent } from './page-layout/successpage/successpage.component';
import { LocationComponent } from './page-layout/location/location.component';
import { ContactusComponent } from './page-layout/contactus/contactus.component';
import { FaquestionComponent } from './page-layout/faquestion/faquestion.component';


export const routes: Routes = [ 
  { path: '', redirectTo: 'Category/1', pathMatch: 'full' },
  {

    path: '',
    component: PageLayoutComponent,
    children: [
      { path: 'Home', component: HomeComponent},
      { path: 'PageHeader', component: PageHeaderComponent },
      //{ path: 'PageFooter', component: PageFooterComponent },
      { path: 'CustomerLogin', component: CustomerLoginComponent },
      { path: 'Login', component: CustomerLoginComponent },
      { path: 'CustomerRegistration', component: CustomerRegistrationComponent },
      { path: 'Aboutus', component: AboutUsComponent },
      { path: 'Bookings', component: BookingComponent },
      { path: 'Cart', component: CartComponent },
      { path: 'Category/:ServiceCat', component: ServicecatsComponent },

      { path: 'BookNow', component: CategoriesComponent },
      { path: 'Addtocart', component: AddCartComponent },
      { path: 'Typeofservices/:SubCategories', component: SubcategoriesComponent },
      { path: 'SuccessPage', component: SuccesspageComponent },
        { path: 'Location', component: LocationComponent },
        { path: 'contactus', component: ContactusComponent },
        { path: 'faq', component: FaquestionComponent }

    ]
  },

]; 

@NgModule({
  //imports: [
  //  RouterModule.forRoot(routes, { useHash: true })
  //],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
          ///relativeLinkResolution: 'legacy',
          useHash: false
    })
  ],
  exports: [
    RouterModule, BrowserModule
  ]
})
export class AppRoutingModule { }

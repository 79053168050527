<br />

<div class="container" style="    background-color: whitesmoke;">
    <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
            <br />
            <div>
                <img class="right" src="../../../assets/img/icon-success-circle.png" alt="hq" />
                <h3>Thanks! You're Booked</h3>
               
            </div>
        </div>
        <div class="col-lg-4">
        </div>
     
        <div class="col-lg-12">
            <p>
                Please sit back and relax, our service engineer will contact you soon...If you have any questions prior
                to your Booking feel free to reach us at <a href="tel:08074507671">+91 8074507671</a></p>
        </div>
    </div>

</div>


<style>
    .listing {
        list-style-type: none;
        padding: 6%;
    }
    @media only screen and (max-width:600px){
        .container{
            margin-top:-19%
        }
    }
</style>

import { GeneralService } from '../../service/general.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'; import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { window } from 'rxjs/operators';
import { EmailValidators } from 'ngx-validators';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'

declare var $;

@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss']
})

export class CustomerRegistrationComponent {
  Emailid: any;
  checkNo: number;
  Mobile: any;
  checkemail: number;
  TemplateId: any;
  Id: any;
  arr: any = [];
  public form: FormGroup;
  public Email: AbstractControl;
  public MobileNo: AbstractControl;
  randomnumber: any;
  HomeUrl: any;
  otptextbox: boolean;
  spinner: boolean = false;
  logindata: any;
  otp: any;
  mob: any;
  LoginType: any;
  passwordshow: boolean = true;
  confirmpasswordshow: boolean = true;
  MessageText: any;
  arr1: any[];
  customerCartItems: any[];
  CartItems: any[];
  currentCartItems: any[];
  customerWishlistItems: any;
  admin: any;
  FirstName: any;
  LastName: any;
    LoginDetails: any;
    LoginDetails1: any;
  constructor(public router: Router, public fb: FormBuilder, public http: HttpClient, public generalService: GeneralService, public cartService: CartService,
    public wishlistService: WishlistService) {
    debugger
    this.LoginType = localStorage.getItem("LoginType");
      this.LoginDetails1 = localStorage.getItem("LoginDetails");
      this.LoginDetails = JSON.parse(this.LoginDetails1);
    //  if (this.LoginDetails != null) {
    //  this.router.navigate['/Home']
    //}
    this.form = fb.group({
      FirstName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      Email: ['',],
      MobileNo: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });

    this.FirstName = this.form.controls['FirstName'];
    this.Email = this.form.controls['Email'];
    this.MobileNo = this.form.controls['MobileNo'];

    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }
  already() {
    localStorage.setItem("log", '1');
    this.router.navigateByUrl("/login");
  }

  SignUp(value) {
    debugger;
    $('#myModal1').modal('show');
    this.Mobile = value.MobileNo;
    this.Sendsms();
  }

  public onSubmit(otp) {
    debugger;
    if (this.form.valid) {
      this.arr = [];
      this.arr.push({
        //CustomerId:0,
        FirstName: this.form.value.FirstName,
        LastName: "",
        Mobile: this.form.value.MobileNo,
        Email: this.form.value.Email,
        Image: "",
        //Password: otp,
        //DeviceToken: "WEB",
        CreatedDate: new Date()
      })
      var UploadFile = new FormData();
      UploadFile.append("customerDetails", JSON.stringify(this.arr));
      var url = "api/Values/CustomerRegistration";
      this.generalService.PostData(url, UploadFile).then(data => {
        if (data != null) {
          if (data == "SUCCESS") {
            debugger;
            this.otp = "";
            this.form.reset();
              this.generalService.ShowAlert('SUCCESS', 'Registration Successfully.', 'success');
             
             
              this.router.navigate(['/Addtocart']);
              
          }
          else if (data == "MOBILEEXIST") {
            this.generalService.ShowAlert('WARNING', 'Mobile Exist.', 'warning')
          }
          else if (data == "EMAILEXISTS") {
            this.generalService.ShowAlert('WARNING', 'Email Exist.', 'warning')
          }
        }
      });
    }
  }

  Sendsms() {
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    var msg = "HQSRVZ Login OTP " + this.randomnumber + " HQSRVZ";
    var UploadFile = new FormData();
    UploadFile.append("mobile", this.form.value.MobileNo);
    UploadFile.append("message", msg);
    UploadFile.append("DLTTEID", "1207161701312347505");
    UploadFile.append("Email", this.form.value.Email);
    var url = 'api/Values/sendSMSToEmail';
    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == "SUCCESS") {

      }
      else {

      }
    });
  }

  /*check otp for registration*/
  CheckOtp(otp) {
    debugger
    if (this.randomnumber == otp) {
      $('#myModal1').modal('hide');
      this.onSubmit(otp);
    }
    else {
      alert("Invalid OTP")
    }
  }

  CheckMobile() {
    var UploadFile = new FormData();
    UploadFile.append("mobile", this.form.value.MobileNo);
    UploadFile.append("flag", "1");
    var url = "api/Values/verifyMobileorEmail";
    this.generalService.PostData(url, UploadFile).then(data => {
      if (data != null) {
        console.log(data)
        if (data == "EXISTS") {
          this.generalService.ShowAlert('WARNING', 'Mobile No. already Exist.', 'warning');
          this.form.get("MobileNo").setValue('');
        }
      }
    });
  }



}
export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

<nav aria-label="breadcrumb" class="breadcrumb1">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page">Our Categories</li>
            <div>Our Categories</div>

        </ul>
    </div>
</nav>
<div class="container-fluid" style="background-color:whitesmoke;">
<div class="container">
   <div *ngIf="gridview">
       <div class="row video" style="text-align:center">
           <div class="col-lg-7">
               <h3>"Welcome to High Quality Air Conditioner: Your Premier Home Appliance Service Provider!"</h3>
               <p>Thank you for choosing High Quality Air Conditioner for all your home appliance needs. <strong>We pride ourselves
               on providing top-notch services for air conditioners, chimneys, refrigerators, televisions, washing machines,
               geysers, and more. </strong>Whether you're in need of installation, repairs, or maintenance, our team of experienced
               professionals is here to ensure your appliances are functioning efficiently and effectively.</p>
               <p>To proceed with booking, please select one of the services listed below. Our dedicated staff is committed to
               delivering prompt and reliable service, ensuring your complete satisfaction with every job. Experience the difference
               with High Quality Air Conditioner and let us take care of all your appliance needs today.</p>
           </div>
           <div class="col-lg-5">
               <img src="../../../assets/img/home_cat.png"class="cat-image" alt="hq" data-aos="zoom-in"/>
           </div>
           </div>
           <div class="container">
               <div class="col-lg-12">
                   <div class="row mobileMargin mb-3 ">

                       <div class="col-lg-2 col-md-2 col-xs-6" *ngFor="let item of dataResult" (click)="getSubCat(item.ServiceCategoryId)" id="click">
                           <br />   <div (click)="getSubCat(item.ServiceCategoryId)" class="card" id="click">
                               <img src="{{HomeUrl+item.Image}}" class="card__img" alt="HQ Ac"><br />
                               <h6 style=" text-align: center;"><b>{{item.ServiceCategory}}</b></h6>

                           </div>
                       </div>
                   </div>
               </div>
           </div>

       </div>
</div>

    </div>
<style>
    @media only screen and (max-width:600px) {
        .breadcrumb1 {
            position: relative;
            /*background: url('assets/img/bg.jpg') center center/cover;*/
            padding: 6px;
            margin-bottom: 5%;
            margin-top: -13%;
        }
            .breadcrumb1 li {
                display: inline-block;
                color: white;
                cursor: pointer;
                font-size: 13px;
            }
    }
</style>




<nav aria-label="breadcrumb" class="breadcrumb1">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/">Contact Us</a> </li>
            <div>Contact Us</div>

        </ul>
    </div>
</nav>
<section class="contact" id="contact">
    <div class="container">
        <div class="section-title" data-aos="fade-up">
            <h2>Contact Us</h2>
        </div>
        <div class="row">
            <div class="col-lg-5 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                <div class="info">
                    <div class="address">
                        <i class="icofont-google-map"></i>
                        <h5>Location:</h5>
                        <p>
                            3rd Floor Sai Arcade, #2-17 &amp; 2-18, Vani Nagar Bus Stop, Opposite Canara Bank, Malkajgiri, Secunderabad, Hyderabad,
                            India- 500047
                        </p>
                    </div>
                    <div class="email">
                        <i class="icofont-envelope"></i>
                        <h5>Email:</h5>
                        <p>
                         <a class="web_mail" href="https://mail.info&#64;gagri.net/contact.html" target="_blank"><i class="fa fa-envelope" style="font-size:14px;color:#fff;"></i>&nbsp;&nbsp;info&#64;gagri.net</a>
                        </p>
                    </div>
                    <div class="phone">
                        <i class="icofont-phone"></i>
                        <h5>Call:</h5>
                        <p><a href="tel: 8074507671">+91  8074507671</a></p>
                    </div>


                    <!--<iframe allowfullscreen="" frameborder="0" height="300"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.1500701613195!2d78.52669141439291!3d17.452529905517885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9bc9cac8cd2b%3A0xb4a970a43aae0b16!2s404%2C%2022%2C%20Gautam%20Nagar%20Rd%2C%20Gautham%20Nagar%2C%20Malkajgiri%2C%20Secunderabad%2C%20Telangana%20500047!5e0!3m2!1sen!2sin!4v1582204060421!5m2!1sen!2sin"
            style="border:0;" width="100%"></iframe>-->
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">


                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <br />
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <br />
                                <button type="submit"style="float:right;" class="button-66" [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

         
        </div>
        <div class="row">
            <div class="col-lg-12"><br />
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d243680.92722182866!2d78.8616369!3d17.3870802!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9bb805df3091%3A0x1f74c165ec2d1d6f!2sGagri+Global+It+Services+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1511854326347" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>

            </div>
        </div>
    </div>
</section>
<style>
    p {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 2;
        text-align: justify;
    }

    @media only screen and (max-width:600px) {
        .breadcrumb1

    {
        position: relative;
        /*background: url('assets/img/bg.jpg') center center/cover;*/
        padding: 6px;
        margin-bottom: 5%;
        margin-top: -13%;
    }
            .breadcrumb1 li {
                display: inline-block;
                color: white;
                cursor: pointer;
                font-size: 13px;
            }
    }
</style>

import { GeneralService } from '../../service/general.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { window } from 'rxjs/operators';
import { EmailValidators } from 'ngx-validators';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss'],
  providers: [MessageService]
})
export class SubcategoriesComponent   {
  EnquiryAry: any = [];
  formGroup: any;
  admin: any;
  TemplateId: any;
  MessageText: any;
  TypeServiceDetailsId: any;
  cartItemList1: any[];
  SubCatId: any;
  productDetails: {};
  TypeServiceDetail: any;
  Description: any;
  CasNumber: any;
  Image: any;
  Price: any;
  Botanicalsource: any;
  CommonName: any;
  Name_ofthe_Active_Supplement: any;
  Specification: any;
  Structure: any;
  OfferingGrades: any;
  Application: any;
  Trademark: any;
  FunctionalActivity: any;
  KeyReferences: any;
  IntendedUse: any;
  Standardization: any;
  CategoryName: any;
  SubCategoryName: any;
  whatsAppProductURL: string;
  HomeUrl: any;
  CatId: any;
  catName: string;
  selectedItemforCart: any = []
  activeroute: any;
  Tests: any[];
    CartStorage1: any;
    CartStorage: any; RateCardList: any;
  constructor(public toast: MessageService,public router: Router, public generalService: GeneralService, public http: HttpClient, public route: ActivatedRoute, public cartService: CartService, public wishlistService: WishlistService) {
    debugger
     this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
         debugger
         this.CartStorage1 = localStorage.getItem('AddtocartArray');
         this.CartStorage = JSON.parse(this.CartStorage1)
      this.TypeServiceDetailsId = this.route.snapshot.paramMap.get('SubCategories').replace(/\_/g, " ").trim();
      this.generalService.getProducts().subscribe(res => {
        if (res.length == 0) {//if category id is null
          this.GetProductDetailsbasedonProductName()
        }
        else {
          this.TypeServiceDetailsId = res[0].TypeServiceDetailsId;
          this.GetProductDetailsbasedonProductName()

        }
      })
    });
  }
  ngOnInit(): void {

  }
  GetProductDetailsbasedonProductName() {
    debugger
    var UploadFile = new FormData()
    UploadFile.append("typeId", this.TypeServiceDetailsId)
    var url = "api/Values/getServiceDetails"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.productDetails = data;
      this.TypeServiceDetailsId = (this.productDetails[0].TypeServiceDetailsId);
      this.TypeServiceDetail = (this.productDetails[0].TypeServiceDetail);
      this.Description = (this.productDetails[0].Description);
    
      this.Price = (this.productDetails[0].Price);
      this.Image = (this.productDetails[0].Image);
     
    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  navigatingtoFarmerProduces(data) {
    var catName = "";
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      catName += "_" + str[i];
    }
    this.router.navigate(['/SubCategories/' + catName]);

  }

    Addtocart(item: any) {
        //if (this.CategoryDetailsinfo1 == undefined && this.CategoryDetailsinfo1 == null && this.CategoryDetailsinfo1 == '' ) {
        debugger
        let formData: FormData = new FormData();
        formData.append('serviceDetailId', item.TypeServiceDetailsId);
        var url = "api/Values/getServiceInfo";
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.RateCardList = data;
            this.Addtocart1(this.RateCardList[0]);
            //this.openModal(this.RateCardList);
        });
        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
            for (var i = 0; i < res.length; i++) {
                this.TypeServiceDetailsId = res[i].ServiceInfoId
                this.Price = res[i].Price
                this.TypeServiceDetail = res[i].ServiceInformation
            }

            return this.cartItemList1

        })
        //    err => {
        //    this.generalService.ShowAlert('Something went wrong. Please try again later.')
        //});

        //}
        
    }

  Addtocart12(item: any) {
    debugger
    //this.cartItemList1 = [];

    this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
      for (var i = 0; i < res.length; i++) {
        this.TypeServiceDetailsId = res[i].ServiceInfoId
        this.Price = res[i].Price
        this.TypeServiceDetail = res[i].ServiceInformation
      }
     
      return this.cartItemList1

    })
   // this.Addtocart1(item);


  }

    Addtocart1(item: any) {
        debugger;
        let itemAlreadyInCart = false;

        for (let i = 0; i < this.selectedItemforCart.length; i++) {
            if (this.selectedItemforCart[i].ServiceInfoId === item.ServiceInfoId) {
                itemAlreadyInCart = true;
                break;
            }
        }

        if (itemAlreadyInCart) {
            this.toast.add({
                severity: 'warn',
                summary: 'Warning',
                life: 2000,
                detail: 'Service already exists in the cart',

            });
        } else {
            this.selectedItemforCart.push({
                TypeServiceDetailsId: item.ServiceInfoId,
                TypeServiceDetail: item.ServiceInformation,
                Price: item.Price,
                Quantity: 1,
            });

            this.cartService.addtoCart(this.selectedItemforCart);
            localStorage.setItem("AddtocartArray", JSON.stringify(this.selectedItemforCart));
            localStorage.setItem("cartCount", this.selectedItemforCart.length);
            this.generalService.ShowAlert('Success', 'Added to cart Successfully', 'Success');
        }
    }


  //Addtocart1(item: any) {
  //  debugger
  //  let itemAlreadyInCart = false;
  //  for (let i = 0; i < this.selectedItemforCart.length; i++) {
  //    if (this.selectedItemforCart[i].TypeServiceDetailsId === item.TypeServiceDetailsId) {
  //      itemAlreadyInCart = true;
  //      break;
  //    }
  //  }
  //  if (itemAlreadyInCart) {
  //      this.toast.add({
  //          severity: 'warn', summary: 'Warning', life: 1000,
  //          detail: 'Service already exists in the cart',
  //          });
  //  }
  //  else {
  //    this.selectedItemforCart.push({
  //      TypeServiceDetailsId: item.TypeServiceDetailsId,
  //      TypeServiceDetail: item.TypeServiceDetail,
  //      Price: item.Price,
  //      Quantity:1
  //    });
  //    this.cartService.addtoCart(this.selectedItemforCart);
  //    this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
  //    }

  //}


  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}


import { Component, OnInit, ViewChild, ElementRef, NgZone, ViewEncapsulation } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
import { MessageService } from 'primeng/api';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { DatePipe } from '@angular/common';
import { debounce } from 'rxjs/operators';
declare var google: any
declare var $: any

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
    styleUrl: './location.component.scss',
    providers: [MessageService]
})
export class LocationComponent {

    Area: any;
    arr: any = [];
    Address: any;
    Landmark: any;
    bookdate: any;
    title: string = 'AGM project';
    latitude: any;
    longitude: any;
    lat: any;
    long: any;
    zoom: number;
    address: string;
    private geoCoder;
    map: boolean
    @ViewChild('search')
    public searchElementRef: ElementRef;
    type: any;
    date: any;
    LoginDetails: any;
    cartItems: any;
    trip: any;
    currentaddres: any;
    LoginDetails1: any;
    HNO: any;

    constructor(public toast: MessageService, public cartService: CartService, private mapsAPILoader: MapsAPILoader, public http: HttpClient, private ngZone: NgZone,
        public wishlistService: WishlistService, public generalService: GeneralService, public router: Router) {
        debugger
        this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

            this.date = new Date();

        })
     
    }

    ngOnInit(): void {

        this.refreshfilds();
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();

            this.geoCoder = new google.maps.Geocoder;

            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    debugger
                    let place: any = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.lat = this.latitude;
                    this.long = this.longitude;
                    this.zoom = 12;
                    this.getAddress(this.latitude, this.longitude);
                });
            });

        });

    }


    refreshfilds() {
        this.type = 'list'
    }

    private setCurrentLocation() {
        debugger
        this.trip = 'newTrip'
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.lat = position.coords.latitude;
                this.long = position.coords.longitude;
                this.getAddress(position.coords.latitude, position.coords.longitude);
            });
        }
    }

    markerDragEnd($event: MouseEvent) {
        this.lat = $event.coords.lat;
        this.long = $event.coords.lng;
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress($event.coords.lat, $event.coords.lng);
    }

    getAddress(latitude, longitude) {
        debugger
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    console.log(results)
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                    this.Address = results[0].formatted_address;
                    this.latitude = latitude;
                    this.longitude = longitude;

                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }


    viewDetails() {
        debugger

        $('#myModal').modal('show');

    }
    close() {
        $('#myModal').modal('hide');

    }

    getadress() {
        this.router.navigate(['/Addtocart']);
        localStorage.setItem("address", this.Address);
        localStorage.setItem("latitude", this.latitude);
        localStorage.setItem("longitude", this.longitude);
    }


    AddnewAddress(HNO: any, Landmark: any, Area: any, flag: any) {
        debugger
        if (this.HNO != undefined || this.Landmark != undefined || this.Area != undefined) {
            let array = [{
                CustomerId: this.LoginDetails[0].CustomerId,
                Area: HNO + ',' + Landmark + '.' + Area,
                Latitude: this.latitude,
                Longitude: this.longitude,
                Address: this.Address,
                CountryId: 1,
                StateId: 1,
                City: 1
            }];
          

        }
        else {
 
            this.generalService.ShowAlert('ERROR', 'please enter all fields.', 'error')
        }
    }



    AddAddress(HNO: any, Landmark: any, Area: any, flag: any) {
        debugger
        this.Address;
        this.latitude;
        this.longitude;
        Address: this.Address;
        if (HNO == '' || HNO == undefined || HNO == null) {
            this.generalService.ShowAlert('Warning', 'Please enter Landmark.', 'Warning')
        }
        else if (Landmark == '' || Landmark == undefined || Landmark == null) {
       
            this.generalService.ShowAlert('Warning', 'Please enter Landmark.', 'Warning')
        }
        
        else {
            debugger
            let array = [{
                CustomerId: this.LoginDetails[0].CustomerId,
                Area: HNO + ',' + Landmark + '.' + Area,
                Latitude: this.latitude,
                Longitude: this.longitude,
                Address :this.Address,
                CountryId: 1,
                StateId: 1,
                City: 1
            }];
            let formData: FormData = new FormData();
            formData.append('addressDetails', JSON.stringify(array));
            formData.append('flag', flag);
            var url = "api/Values/CustomerAddressCUD";
            this.generalService.PostData(url, formData).then((data: any) => {
                debugger
                if (data = 'SUCCESS') {
                    this.router.navigate(['/Addtocart']);
                }
                else {
                    this.generalService.ShowAlert('Warning', 'Somthig went wrong.', 'Warning')
                }
            }, (err: any) => {
                this.generalService.ShowAlert('Warning', 'Somthig went wrong.', 'Warning')
            });
        }
    }
}

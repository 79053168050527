
  <div class="fixed-top">
  
      <div class="container-fluid mobile_top " style="background-image: linear-gradient(135deg, #005bea, #00c6fb );">
          <!--background-image: linear-gradient(to right, #487ad7, #60d960);-->
          <div class="container">
              <div class="row">
                  <div class="col-lg-2">
                      <a href="https://mail.info&#64;gagri.net/contact.html" target="_blank" style="font-size:14px;color:white;">
                          <p> <i class="fa fa-envelope"></i>&nbsp;&nbsp;info&#64;gagri.net</p>
                      </a>

                  </div>
                  <div class="col-lg-2">
                      <a href="tel:+918074507671" style="font-size:14px;color:white;">
                          <p style="margin-top:10%;"> Call +91 8074507671</p>
                      </a>

                  </div>
                  <!--<div class="col-lg-3">
                      <a style="font-size:14px;color:white;" href="tel:+918074507671">
                          <p style="color:white;">Call&nbsp;+91&nbsp;8074507671</p>
                      </a>
                  </div>-->
                  <div class="col-lg-6"></div>
                  <div class="col-lg-2">
                      <ul style="float: inline-end; font-size: 20px;">
                          <li>
                              <a href="https://www.facebook.com/profile.php?id=100077858388799" target="_blank"><i style="color:white;" class="fa fa-facebook"></i></a>
                          </li>
                          <li>
                              <a href="https://instagram.com/hqserviz?igshid=YmMyMTA2M2Y=" target="_blank"><i style="color:white;" class="fa fa-instagram"></i></a>
                          </li>
                      </ul>
                  </div>
              </div>
              </div>
      </div>
    <header id="header">
      <div class="container-fluid fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-2">
                    <img src="../../assets/img/Logo_1_1.png" alt="hq" style="width:50px;border-radius:10px" />
                </div>
             
                <div class="col-lg-10">
                    <nav class="nav-menu navbar-right d-none d-lg-block" style="float:right;">
                        <ul>
                            <li class="active"><a routerLink="Home">Home</a></li>
                            <li class="active_1"><a routerLink="Aboutus">About HQ</a></li>
                            <li class="active_2"><a routerLink="BookNow">Categories</a></li>
                            <li class="active_3"><a routerLink="faq">FAQs</a></li>
                            <li class="active_4"><a routerLink="contactus">Contact Us</a></li>
                            <li><a routerLink="BookNow" style="color:white;margin-top: -11px;"><button class="button-66">BOOK NOW</button></a></li>
                            <li class="active_4" *ngIf="!LoginDetails"><a routerLink="/Login">Login</a></li>
                            <li class="active_4" *ngIf="LoginDetails"><a (click)="logout()">Logout</a></li>
                            <li class="active_6">
                                <a (click)="addtocart()" class="nav-link active cart1">
                                    <i class="fa fa-cart-plus plus" aria-hidden="true" style="font-size: 25px;">
                                        <span style="font-family: 'Poppins';font-size: 21px;font-weight: normal;"> ({{countReturn()}})</span>
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
      </div>
  
      <div class="container-fluid web">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <a routerLink="/Home">
                <img src="../../assets/img/Logo_1_1.png" alt="hq" style="width: 50px;    border-radius: 5px; margin-top: -1rem; margin-left: 1rem" />
              </a>
    
            </div>
            <div class="col-lg-3">
              <div class="book "><a routerLink="/BookNow" style="color:white;">BOOK NOW</a></div>

            </div>
            <div class="col-lg-3">
              <div class="cart">
                <a routerLink="/Addtocart" class="nav-link active cart1">
                  <i class="fa fa-cart-plus plus" aria-hidden="true">
                      <span style="font-family: 'Poppins';font-size: 18px;font-weight: normal;"> ({{countReturn()}})</span>
                  </i>
                </a>
              </div>
            </div>
      
          </div>
        </div>
      </div>
    </header>

  </div>





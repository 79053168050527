import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  public wishlistItem: any = []
  public prodctList = new BehaviorSubject<any>([]);

  constructor() { }

  getProducts() {
    return this.prodctList.asObservable();
  }
  setProducts(product: any) {
    this.wishlistItem.push(product);
    this.prodctList.next(this.wishlistItem)
  }
  removeWishlistItems(index: any) {    
    this.wishlistItem.splice(index, 1)
    this.prodctList.next(this.wishlistItem);
  }
  removeAllWishlistItems() {
    this.wishlistItem=[]
    this.prodctList.next(this.wishlistItem);
  }
}

import { GeneralService } from '../../service/general.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { window } from 'rxjs/operators';
import { EmailValidators } from 'ngx-validators';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
@Component({
  selector: 'app-servicecats',
  templateUrl: './servicecats.component.html',
  styleUrls: ['./servicecats.component.scss']
})
export class ServicecatsComponent   {

  HomeUrl: any;
  add: boolean = true;
  dataResult: any = [];
  arr: any[];
  dataResult1: any = [];
  Products: any = [];
  ProductsList: any = [];
  TypeServicesCategoryID: any;
  CatID: 3;
  dataResult11: any;
  TypeServicesCategoryId: number;
  allview: boolean;
  searchcompany: any;
  searchlist: any;
  selectedproducts: any;
  searchcomp: any;
  ProductName: any;
  gridview: boolean = true;
    ServiceCategoryId: any;

  constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {
    debugger
    /*this.ProductName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();*/
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
      this.ServiceCategoryId = this.activeroute.snapshot.paramMap.get('ServiceCat').replace(/\_/g, " ").trim();
      this.generalService.getProducts().subscribe(res => {
        if (res.length == 0) {//if category id is null
          this.getSubCat()
        }
        else {
          this.ServiceCategoryId = res[0].ServiceCategoryId;
          this.getSubCat()

        }
      });
    });
  }

  ngOnInit(): void {
    
  }



  getSubCat() {
    debugger
    this.gridview = true
    var UploadFile = new FormData();
    UploadFile.append("categoryId",this. ServiceCategoryId);
    var url = "api/Values/getTypeServiceCategories";
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      this.Products = data
     

    }, err => {
    })
  }


  navigatingToSelectedProduct(data) {
    debugger
    this.router.navigate(['/Typeofservices/' + data]);
  }




  rating1(el: HTMLElement) {

    el.scrollIntoView();
  }
  rating(el: HTMLElement) {

    el.scrollIntoView();
  }
}



<p-toast position="bottom-center"></p-toast>
<nav aria-label="breadcrumb" class="breadcrumb1">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/BookNow">Our Category</a> </li>
            <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/Category/3">Service Categories</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"> Type of Service</li>
            <div>Type of Service</div>

        </ul>
    </div>
</nav>
<div class="container-fluid"style="background-color:whitesmoke;">
<div class="container">
    <div class="col-lg-4 col-md-4 " *ngFor="let item of productDetails">
    <div class="card">
      <img src="{{HomeUrl+item.Image}}" class="img_product" alt="hq"/>
      <br>
      <div class="card_heading">
        <h3> <b>{{item.TypeServiceDetail}}</b></h3>
      </div>

      <div class="card_des"> {{item.Description}}</div>
      <br>
      <div class="row">
        <div class="col-lg-6 col-xs-6">
          <div class="card_price">
            <b>₹ {{item.Price}}.00</b>
          </div>
        </div>
        <div class="col-lg-6 col-xs-6">
          <button type="button" class="button-66" (click)="Addtocart(item)"><b>Add To Cart</b></button>

        </div>
      </div>

    </div>
  </div>

</div></div>
<style>
    @media only screen and (max-width:600px) {
        .p-toast-bottom-center {
            bottom: 352px;
            left: 15%;
            transform: translateX(-14%);
            width: -78px;
            color: red;
            /* height: 45px; */
        }

        .p-component, .p-component * {
            box-sizing: border-box;
            margin-left: 3% !important;
            width: 119%;
        }
    }

    @media only screen and (max-width:600px) {
        .breadcrumb1{
        position: relative;
        /*background: url('assets/img/bg.jpg') center center/cover;*/
        padding: 6px;
        margin-bottom: 5%;
        margin-top: -13%;
    }
            .breadcrumb1 li {
                display: inline-block;
                color: white;
                cursor: pointer;
                font-size: 13px;
            }
            .col-xs-6{
                padding:10px;
                width:50%;
            }
    }
</style>

<script type="text/javascript">
  var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5cf905f9267b2e57853125fc/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
</script>

<app-page-header></app-page-header>
<div style="margin-top:100px;">
  <router-outlet></router-outlet>
</div>

<!--<div class="sticky-container">
  <ul class="sticky">
    <li>
      <img src="../../assets/media/call-circle.png" alt="hq" width="32" height="32">
      <p><a href="tel:+919667661333">Call us<br>Schedule Appointment</a></p>
    </li>
    <li>
      <img src="../../assets/media/facebook-circle.png" alt="hq" width="32" height="32">
      <p><a href="https://www.facebook.com/YKLaboratories-1985759904790008" target="_blank">Like Us on<br>Facebook</a></p>
    </li>
    <li>
      <img src="../../assets/media/twitter-circle.png" alt="hq" width="32" height="32">
      <p><a href="https://www.instagram.com" target="_blank">Follow Us on<br>instagram</a></p>
    </li>
    <li>
      <img src="../../assets/media/youtube-circle.png" alt="hq" width="32" height="32">
      <p><a href="https://www.youtube.com/channel/UCVjI7ITo2S3Zta6bl31DVBA" target="_blank">Subscribe on<br>Youtube</a></p>
    </li>
    <li>
      <img src="../../assets/media/whatsapp.png" alt="hq" width="32" height="32">
      <p><a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Contact<br>YK Laboratories</a></p>
    </li>

  </ul>
</div>-->
<app-page-footer></app-page-footer>

<style>
  .whatsapp {
    position: fixed;
    top: 60%;
    z-index: 99;
    right: -80px;
  }

  .social {
    margin-left: -78px;
    width: 120px;
    padding: 0;
    display: inline-table;
    height: 0px;
    background-color: #04c165;
    -moz-transition-property: margin-left;
    -moz-transition-duration: 0.2s;
    -moz-transition-delay: 0.2s;
    -ms-transition-property: margin-left;
    -ms-transition-duration: 0.2s;
    -ms-transition-delay: 0.2s;
    -o-transition-property: margin-left;
    -o-transition-duration: 0.2s;
    -o-transition-delay: 0.2s;
    box-shadow: 0px 0px 6px 0px #3e3d3d66;
    cursor: pointer;
    border-radius: 5px;
  }

    .social:hover {
      margin-right: 80px;
      width: 223px;
      background-color: #3b5998;
      color: #ffffff !important;
    }

  .Whatsapp:hover {
    background-color: #04c165;
  }
</style>

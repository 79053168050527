import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  ServiceTypes: any;
  CategoryTypes: any;
  cartItems: any = [];
  TypeName: any;
  public router: Router;
  HomeURL: any;
  Banners: any;
    EnquiryAry: any=[];
    name: any;
    email: any;
    subject: any;
    number: any;
    message: any;

  constructor(public generalService: GeneralService, public http: HttpClient, router: Router, ) {
    this.router = router
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
 
  }

  ngOnInit(): void {
  
  }

  getCetogeryType() {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("categoryId", "1");
    var url = "api/Values/getTypeServiceCategories";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data != null) {
        this.CategoryTypes = data;
        //console.log(this.CategoryTypes)
      }
    });
  }
  getServices(category:any) {
    debugger;
    this.TypeName = category.TypeServiceCategory;
    var UploadFile = new FormData();
    UploadFile.append("typeId", category.TypeServicesCategoryId);
    var url = "api/Values/getServiceDetails";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data != null) {
        this.ServiceTypes = data;
        //console.log(this.ServiceTypes)
      }
    });
  }
  addToCart(obj: any) {
    var index = this.cartItems.findIndex(re => re.TypeServiceDetailsId == obj.TypeServiceDetailsId);
    if (index == -1 || this.cartItems.length == 0) {
      this.cartItems.push(obj);
      this.generalService.ShowAlert('SUCCESS', 'successfully added to cart.', 'success');
    } else {
      this.generalService.ShowAlert('WARNING', 'Already Exist.', 'warning')
    }
    localStorage.setItem('cart', JSON.stringify(this.cartItems))
    //console.log(this.cartItems);
  }

    //start EnquiryMailTo_YKAdmin sending mail through db --manohar
    onSubmit(form: any) {
        debugger;

        this.EnquiryAry = [];
        this.EnquiryAry.push({
            FarmerName: form.name,
            Mobile: form.number,
            EmailID: form.email,
            Subject: form.subject,
            Message: form.message,
        })
        var UploadFile = new FormData();
        UploadFile.append("Email", JSON.stringify(this.EnquiryAry));
        var url = "/api/Values/EnquiryMailTo_HQAdmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
            this.generalService.ShowAlert("SUCCESS", "Your Mail Sent Successfully", "Success");

            //Swal.fire(
            //    'SUCCESS',
            //    'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
            //    'success')
            this.name = '';
            this.email = '';
            this.subject = '';
            this.number = '';
            this.message = '';
            window.location.reload();

        });
        this.generalService.ShowAlert("SUCCESS", "Your Mail Sent Successfully", "Success");

    }
    // mail code end 



}

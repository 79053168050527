import { Component,OnInit,ViewChild } from '@angular/core';
import { GeneralService } from '../../service/general.service';
import { FormBuilder, FormControl, Validator, FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrl: './contactus.component.scss'
})
export class ContactusComponent {
    EnquiryAry: any=[];
    name: any;
    email: any;
    subject: any;
    number: any;
    message: any;


    constructor(public generalService: GeneralService, public fb: FormsModule,
        private http: HttpClient,) { }


    //start EnquiryMailTo_YKAdmin sending mail through db --manohar
    onSubmit(form: any) {
        debugger;

        this.EnquiryAry = [];
        this.EnquiryAry.push({
            FarmerName: form.name,
            Mobile: form.number,
            EmailID: form.email,
            Subject: form.subject,
            Message: form.message,
        })
        var UploadFile = new FormData();
        UploadFile.append("Email", JSON.stringify(this.EnquiryAry));
        var url = "/api/Values/EnquiryMailTo_HQAdmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;
            this.generalService.ShowAlert("SUCCESS", "Your Mail Sent Successfully", "Success");

            //Swal.fire(
            //    'SUCCESS',
            //    'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
            //    'success')
            this.name = '';
            this.email = '';
            this.subject = '';
            this.number = '';
            this.message = '';
            window.location.reload();

        });
        this.generalService.ShowAlert("SUCCESS", "Your Mail Sent Successfully", "Success");

    }
    // mail code end 


}

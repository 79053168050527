import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/*import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';*/
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppComponent } from './app.component';
/*Page-Layout components import place*/
import { PageLayoutComponent } from './page-layout/page-layout.component'
import { HomeComponent } from './page-layout/home/home.component'
import { PageHeaderComponent } from './page-layout/page-header/page-header.component'
import { PageFooterComponent } from './page-layout/page-footer/page-footer.component'
import { CustomerLoginComponent } from './page-layout/customer-login/customer-login.component'
import { ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
//import { google } from '@agm/core/services/google-maps-types';
//import { GMapModule } from 'primeng/gmap'
import { SpinneroverlayComponent } from 'src/app/service/spinneroverlay/spinneroverlay.component';
import { CustomerRegistrationComponent } from './page-layout/customer-registration/customer-registration.component';
import { UVideoSafe } from './Uvideosafe';
import { AboutUsComponent } from './page-layout/about-us/about-us.component';
import { BookingComponent } from './page-layout/booking/booking.component';
import { CartComponent } from './page-layout/cart/cart.component';
import { CategoriesComponent } from './page-layout/categories/categories.component';
import { SubcategoriesComponent } from './page-layout/subcategories/subcategories.component';
import { AddCartComponent } from './page-layout/add-cart/add-cart.component';
import { ServicecatsComponent } from './page-layout/servicecats/servicecats.component';
import { LocationComponent } from './page-layout/location/location.component';
import { ContactusComponent } from './page-layout/contactus/contactus.component';
import { FaquestionComponent } from './page-layout/faquestion/faquestion.component';


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        NgbModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        CKEditorModule,
        ImageCropperModule,
        ToastModule,
        
      AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
       
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ToastrModule.forRoot(),
        PipesModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

    ],
    declarations: [
        AppComponent,
        PageLayoutComponent,
        HomeComponent,
        PageHeaderComponent,
        PageFooterComponent,
        CustomerLoginComponent,
        CustomerRegistrationComponent,
        UVideoSafe,
        AboutUsComponent,
        BookingComponent,
        CartComponent,
        CategoriesComponent,
        SubcategoriesComponent,
        AddCartComponent,
        ServicecatsComponent,
        SpinneroverlayComponent,
        LocationComponent,
        ContactusComponent, FaquestionComponent
        
  
    ],
    providers: [
        AppSettings,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    bootstrap: [AppComponent],
    //entryComponents: [
       
    //],
    exports: [AppRoutingModule]
})
export class AppModule { } 

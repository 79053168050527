<nav aria-label="breadcrumb" class="breadcrumb1" >
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/BookNow">Our Category</a> </li>
            <li class="breadcrumb-item bread_active" aria-current="page">Service Categories</li>
            <div>Our Category</div>
        </ul>
    </div>
</nav>

<div class="category"style="background-color:whitesmoke;margin-top:-3%;">
  <div class="container">
       <div class="row video" style="text-align:center;">
        <div class="col-lg-4">
            <video controls autoplay class="video_mobile">
                <source src="../../../assets/img/New%20Images/Hq_Video.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="col-lg-8">
            <img src="../../../assets/img/CANVA.PNG" class="cat-image"/>
        </div>
        </div>
      <div class="row">
          <div class="col-lg-12">
              <br />
              <p class="categoryhead">
                  Thank you for choosing High Quality Air Conditioner Services. To proceed with booking, please select one of the services listed below.
              </p>
          </div>
      </div>
        <div class="container-fluid">
            <div class="row mobilemargin mb-3">

                <div class="col-lg-2 col-md-2  col-xs-6" *ngFor="let item1 of Products" style="cursor:pointer;" (click)="navigatingToSelectedProduct(item1.TypeServicesCategoryId)">

                    <div id="click" class="card" style="cursor:pointer;" (click)="navigatingToSelectedProduct(item1.TypeServicesCategoryId)">

                        <img src="{{HomeUrl+item1.Image}}" class=" card__img" alt="hq"><br />
                        <h6 style="text-align:center;"><b>{{item1.TypeServiceCategory}}</b></h6>
                    </div>
                </div>
                <br />
            </div>
        </div>

    </div>
</div>
<style>
    @media only screen and (max-width:600px) {
        .breadcrumb1 {
            position: relative;
            /*background: url('assets/img/bg.jpg') center center/cover;*/
            padding: 6px;
            margin-bottom: 5%;
            margin-top: -13%;
        }
            .breadcrumb1 li {
                display: inline-block;
                color: white;
                cursor: pointer;
                font-size: 13px;
            }
    }
</style>

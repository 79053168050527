<nav aria-label="breadcrumb" class="breadcrumb1">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Addtocart">Cart</a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"> Location </li>
            <div>Location</div>

        </ul>
    </div>
</nav>

<div class="container-fluid" style="background-color:whitesmoke">
<div class="container">
    <div class="row">
        
        <div class="col-lg-4">
            <button class="mapadress " style=" cursor:pointer;" (click)="getadress()">
                Back
            </button>
        </div>
        <div class="col-lg-2"></div>
                <div class="col-lg-6">
                    <button class="address" (click)="AddAddress(HNO,Landmark,Area,1)">
                        Add Address

                    </button>
                </div>
           
        
            <div class="col-lg-12">
                <div class="container form1">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-lg-6" style="position:relative;padding: 5px;">
                                <div>
                                    <label>House No</label><br />
                                    <input type="text" class="Inputt" [(ngModel)]="HNO" placeholder="H.No."><br />

                                </div><br />
                                <div>
                                    <label>Land mark</label><br />
                                    <input type="text" class="Inputt" [(ngModel)]="Landmark" placeholder="Landmark"><br />

                                </div><br />
                                <div>
                                    <label>Area/Line/Street</label><br />
                                    <input type="text" class="Inputt" [(ngModel)]="Area" placeholder="Area"><br>

                                </div>

                            </div>

                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12 mb-5 laddress">
                                        <label>select address</label>
                                        <input type="text" class="form-control" [(ngModel)]="address" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                    </div>
                                    <br />  <br />
                                    <div class="col-lg-12">
                                        <label for="email-b-f"><b>selcet your correct Address in google map</b> </label><br />
                                        <textarea rows="4" disabled [(ngModel)]="Address" cols="50" placeholder="selcet your correct Address in google map" name="Address" style="width:100%;padding-left:2%;border-radius:5px;" required></textarea>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <!--<button class="gradient" (click)="AddAddress(HNO,Landmark,Area,1)">
                        Add Address

                    </button>-->
                    </div>

                    <br />
                </div>

            </div>

            <div class="col-lg-12 mapp">
                <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom">
                    <agm-marker [latitude]="lat" [longitude]="long" [markerDraggable]="true"
                                (dragEnd)="markerDragEnd($event)"></agm-marker>
                </agm-map>
            </div>
        </div>
    </div><br /></div>
<style>
    @media only screen and (max-width:600px) {
        .breadcrumb1 {
            position: relative;
            /*background: url('assets/img/bg.jpg') center center/cover;*/
            padding: 6px;
            margin-bottom: 5%;
            margin-top: -13%;
        }
            .breadcrumb1 li {
                display: inline-block;
                color: white;
                cursor: pointer;
                font-size: 13px;
            }
        .mapadress {
            padding: 10px;
            border: none;
            background-color: darkblue;
            border-radius: 5px;
            color: white;
            width: 18%;
            margin: -7% 0% 3% 0%;
            cursor: pointer;
        }
    }
</style>
